// src/components/mdxLayouts/MdxLayoutSection.js

import React from "react";
import PropTypes from "prop-types";
import { MDXRenderer } from "gatsby-plugin-mdx";

import path from "ramda/src/path";
import curry from "ramda/src/curry";
import find from "ramda/src/find";
import compose from "ramda/src/compose";
import lensPath from "ramda/src/lensPath";
import view from "ramda/src/view";

/*
 * *** MdxLayoutSection  ***
 * --------------------------
 * - wraps the MDXRenderer graphql queried markdown @see https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#mdxrenderer
 * - markdown-files are mdxSections with suffix en / de and frontmatter shortcut
 * - language selection is done before in graphql query by sufix
 * - then here get node from graphql tree by unique shortcut
 * - @remember props all passed in mdx, e.g. graphql images, frontmatter, ...
 * - @remember layout for the markdown and maybe components are direktly definied in markdown
 */

const pathPageMdx = path(["data", "mdxComponents", "nodes"]);
const shortcutLens = lensPath(["frontmatter", "shortcut"]);
// whereShortcutIs :: String, {} -> Boolean
const whereShortcutIs = curry((str, obj) => {
  const shortcut = view(shortcutLens, obj);
  return shortcut === str;
});
// mdxByShortcut :: String, {} -> {}
export const mdxByShortcut = curry((str, props) =>
  compose(find(whereShortcutIs(str)), pathPageMdx)(props)
);

export const MdxLayoutSection = ({ shortcut, ...props }) => {
  // return null
  
  const mdx = mdxByShortcut(shortcut, props);
  // return null
  if (!mdx) {
    return (
      <div className="text-white bg-red-700 baseBold">{`ERROR: MdxLayoutSection "${shortcut}" does not exist.`}</div>
    );
  }
  

  return (
    <MDXRenderer {...props} {...mdx}>
      {mdx.body}
    </MDXRenderer>
  );
};
MdxLayoutSection.propTypes = {
  // shortcut related to frontmatter in mdx
  shortcut: PropTypes.string.isRequired,
  // data graphql queried
  data: PropTypes.object.isRequired, // {data:{ querriedImages{nodes}, pageMx: {nodes:{ frontmatter: shortcut} } },
};
