import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { MdxLayoutSection } from "../components/mdxLayouts/MdxLayoutSection";

// @remember cause gatsby-node.js is node require moduel,
// this also needs to be a require module
// const slug = require(`../utils/slug`);

// const LastBlogArticles = ({ data }) => {
//   const posts = data.allMdx.nodes;
//   return (
//     <section>
//       <h2 className="text-2xl"> My Last three posts</h2>
//       <ol>
//         {posts.map((post) => {
//           const title = post.frontmatter.title || post.fields.slug;

//           return (
//             <li key={post.fields.slug}>
//               <article
//                 className="post-list-item"
//                 itemScope
//                 itemType="http://schema.org/Article"
//               >
//                 <header className="pt-6 pb-2">
//                   <h2 className="font-bold">
//                     <Link
//                       to={slug.getPathFromBlogFolderName(post.fields.slug)}
//                       itemProp="url"
//                     >
//                       <span itemProp="headline">{title}</span>
//                     </Link>
//                   </h2>
//                   <small>{post.frontmatter.date}</small>
//                 </header>
//                 <section>
//                   <p
//                     dangerouslySetInnerHTML={{
//                       __html: post.frontmatter.description || post.excerpt,
//                     }}
//                     itemProp="description"
//                   />
//                 </section>
//               </article>
//             </li>
//           );
//         })}
//       </ol>
//     </section>
//   );
// };

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Hello" />
      <MdxLayoutSection shortcut="intro_text" data={data} />
      <MdxLayoutSection shortcut="intro_portfolio" data={data} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mdxComponents: allMdx(
      filter: { fileAbsolutePath: { regex: "/components/" } }
    ) {
      nodes {
        body
        frontmatter {
          shortcut
        }
      }
    }
  }
`;

// @ remember mdx for last three blog-post
// allMdx(
//   limit: 3
//   filter: { fileAbsolutePath: { regex: "/blog/" } }
//   sort: { fields: [frontmatter___date], order: DESC }
// ) {
//   nodes {
//     excerpt
//     fields {
//       slug
//     }
//     frontmatter {
//       date(formatString: "MMMM DD, YYYY")
//       title
//       description
//     }
//   }
// }
